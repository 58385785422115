/** @format */

import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Spinner,
  Form,
  Fieldset,
  Layout,
  FormField,
  Checkbox,
  CheckboxGroup,
  Combobox,
  DatePicker,
  InfoIcon,
  TimePicker,
  Button,
  ErrorMessage,
  Input,
} from "@vwfs-bronson/bronson-react";
import { useDispatch } from "react-redux";
import { addNotificationModal } from "../../actions/notificationAction";
import { useInstallation } from "../../hooks/useInstallation";
import { useVehicle } from "../../hooks/useVehicle";
import { useForm } from "../../hooks/useForm";
import { adapterAvailableSearchFormRequest } from "../../adapters/adapterAvailableSearchFormRequest";
import {
  getAvailableVehicles,
  resetVehicleAvailables,
} from "../../actions/vehicleAction";
import {
  setSelectedInstallation,
  setSelectedInstallationMap,
  setSelectedBigger25,
} from "../../actions/summaryAction";
import { roundTimeQuarterHour } from "../../helpers/roundTimeQuarterHour";
import { installationFormValidateDates } from "../../helpers/installationFormValidateDates";
import { installationFormValidateSchedules } from "../../helpers/installationFormValidateSchedules";
import { useSummary } from "../../hooks/useSummary";
import moment from "moment";
import { ScheduleInfoModal } from "../ScheduleInfoModal";
import { setShowSchedulesInfoModal } from "../../actions/globalsAction";
import { postRequest } from "../../config/axiosClient";
export const InstallationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { installations } = useInstallation();
  const { vehiclesAvailables, vehiclesAvailablesLoading } = useVehicle();
  const {
    selectedTariff,
    selectedInstallation,
    selectedInstallationMap,
    selectedBigger25,
  } = useSummary();

  const nowMomentDate = moment();

  const [installation, setInstallation] = useState(null);

  const [showFormError, setShowFormError] = useState(false);

  const [formValues, handleOnChange] = useForm({
    StartDate: nowMomentDate.format("DD/MM/yyyy"),
    StartTime: moment(roundTimeQuarterHour(nowMomentDate.toDate())).format(
      "HH:mm A"
    ),
    EndDate: nowMomentDate.add(1, "day").format("DD/MM/yyyy"),
    EndTime: moment(roundTimeQuarterHour(nowMomentDate.toDate())).format(
      "HH:mm A"
    ),
  });
  const [schedules, setSchedules] = useState([]);
  const getSchedules = useCallback(async () => {
    const { data } = await postRequest("schedule", installation.key);
    setSchedules(data);
  }, [installation]);
  const handleOnChangeCombobox = (e) => {
    if (e && e.length > 0) {
      setInstallation(e[0]);
    }
  };
  useEffect(() => {
    if (installation) {
      getSchedules();
    }
  }, [installation, getSchedules]);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    let waitToModal = false;
    if (installationFormValidateDates(formValues)) {
      if (installation != null && schedules != null && schedules.length > 0) {
        if (!installationFormValidateSchedules(schedules, formValues)) {
          const selectedInst = installations.filter(
            (x) => x.id.toString() === installation.key.toString()
          );
          const obj = {
            ...formValues,
            installationId: installation.key,
            fareId: selectedTariff.Id,
          };
          selectedInst[0].obj = obj;
          dispatch(setShowSchedulesInfoModal(selectedInst[0]));
          waitToModal = true;
          // return;
        }
      }
      if (
        formValues.PromotionalCode !== null &&
        formValues.PromotionalCode !== undefined &&
        formValues.PromotionalCode.trim() !== ""
      ) {
        const obj = {
          startDate: moment(
            formValues.StartDate + " " + formValues.StartTime,
            "DD/MM/YYYY HH:mm A"
          ).toDate(),
          endDate: moment(
            formValues.EndDate + " " + formValues.EndTime,
            "DD/MM/YYYY HH:mm A"
          ).toDate(),

          promotionalCode: formValues.PromotionalCode,
          installationId: installation.key,
          fareId: selectedTariff.Id,
        };
        var { data } = await postRequest("promotionalCodeValidate", obj);
        if (data === "False") {
          dispatch(
            addNotificationModal("El código promocional no es válido. ")
          );
          return;
        }
      }
      const start = new Date(
        moment(
          formValues.StartDate + " " + formValues.StartTime,
          "DD/MM/YYYY HH:mm A"
        )
      );
      const end = moment(
        formValues.EndDate + " " + formValues.EndTime,
        "DD/MM/YYYY HH:mm A"
      ).toDate();
      if (new Date(start.setHours(start.getHours() + 1)) > end) {
        dispatch(
          addNotificationModal(
            "Se debe dejar al menos 1 hora de margen entra la recogida y la entrega."
          )
        );
        return;
      }
      if (installation && !waitToModal) {
        setShowFormError(false);
        const obj = {
          ...formValues,
          installationId: installation.key,
          fareId: selectedTariff.Id,
        };
        dispatch(getAvailableVehicles(adapterAvailableSearchFormRequest(obj)));
        return;
      }

      if (selectedInstallation && !waitToModal) {
        setShowFormError(false);
        const obj = {
          ...formValues,
          installationId: selectedInstallation.id,
          fareId: selectedTariff.Id,
        };
        dispatch(getAvailableVehicles(adapterAvailableSearchFormRequest(obj)));
        return;
      }
    }
    if (!waitToModal) setShowFormError(true);
  };
  /*
  const calculateMinTime = () => {
    let value = Math.trunc(nowMomentDate.get("minute") / 60) + 1;
    if (value === 1) {
      nowMomentDate.set("hour", nowMomentDate.get("h") + 1);
    }
    return 0;
  };*/

  useEffect(() => {
    if (
      selectedInstallation !== null &&
      selectedInstallation.id !== null &&
      selectedInstallation.id !== undefined
    ) {
      const localInstalation = selectedInstallation;
      dispatch(setSelectedInstallation(null));
      setInstallation({
        key: localInstalation.id.toString(),
        value: `${localInstalation.name} (${localInstalation.province})`,
      });
    }
    if (vehiclesAvailables.length > 0) {
      if (installation) {
        const selectedInst = installations.filter(
          (x) => x.id.toString() === installation.key.toString()
        );
        dispatch(setSelectedInstallation(selectedInst[0]));
        navigate("/vehicles");
      }

      if (selectedInstallation) {
        navigate("/vehicles");
      }
    }
  }, [
    vehiclesAvailables,
    navigate,
    dispatch,
    installations,
    installation,
    selectedInstallation,
    selectedInstallationMap,
  ]);

  useEffect(() => {
    if (
      moment(formValues.StartDate, "DD/MM/yyyy").toDate() >
      moment(formValues.EndDate, "DD/MM/yyyy").toDate()
    ) {
      handleOnChange({
        target: {
          name: "EndDate",
          value: moment(formValues.StartDate, "DD/MM/yyyy").format(
            "DD/MM/yyyy"
          ),
        },
      });
    }
  }, [formValues, handleOnChange, nowMomentDate]);

  useEffect(() => {
    dispatch(resetVehicleAvailables());
  }, [dispatch]);

  return (
    <Spinner busy={vehiclesAvailablesLoading} section>
      <Form autoComplete="off" onSubmit={handleOnSubmit} floatingLabel>
        <React.Fragment key=".0">
          <Fieldset>
            <Fieldset.Row>
              <FormField
                labelId="combobox-label-1"
                labelText="¿Dónde te viene mejor recogerlo?"
                type="other"
              >
                <Combobox
                  ariaLabelledBy="element-id-to-label-combobox"
                  id="combo-searchable"
                  name="combo-searchable"
                  onChange={handleOnChangeCombobox}
                  noOptionsMessage="No se han encontrado resultados."
                  placeholder="Introduce una provincia, concesionario o localidad"
                  searchable
                >
                  {installations &&
                    installations.map((x) => (
                      <Combobox.Item
                        key={`Combobox_${x.id}`}
                        optionKey={x.id.toString()}
                        optionValue={`${x.name} (${x.province})`}
                      >
                        {x.name} ({x.province})
                      </Combobox.Item>
                    ))}
                </Combobox>
                <span className="u-font-size-fs-2">
                  * Selecciona en el mapa o busca por concesionario o provincia.
                </span>
              </FormField>
            </Fieldset.Row>
          </Fieldset>
          <Fieldset>
            <Fieldset.Row>
              <Layout>
                <Layout.Item>
                  <FormField
                    labelId="startDateLabel"
                    labelText="Día y hora de recogida"
                    noFloatingLabel
                    type="other"
                  >
                    <Layout>
                      <Layout.Item default="1/2" s={"1/1"}>
                        <DatePicker
                          dateFormat="d/m/Y"
                          name={"StartDate"}
                          monthSelectorDropdown={true}
                          flatpickrOptions={{
                            onClose: (e) => {
                              handleOnChange({
                                target: {
                                  name: "StartDate",
                                  value: moment(e[0]).format("DD/MM/yyyy"),
                                },
                              });
                            },
                          }}
                          onChange={(e) => {
                            handleOnChange({
                              target: {
                                name: "StartDate",
                                value: e,
                              },
                            });
                          }}
                          locale="es"
                          value={formValues.StartDate}
                          minDate={nowMomentDate
                            .add(-1, "day")
                            .format("DD/MM/yyyy")}
                        />
                      </Layout.Item>
                      <Layout.Item default="1/2" s={"1/1"}>
                        <TimePicker
                          name={"StartTime"}
                          value={formValues.StartTime}
                          locale="es"
                          minTime={nowMomentDate.format("HH:mm")}
                          onChange={(e) =>
                            handleOnChange({
                              target: { name: "StartTime", value: e },
                            })
                          } /*
                          flatpickrOptions={{
                            onClose: (e) => {
                              handleOnChange({
                                target: {
                                  name: "StartDate",
                                  value: moment(e[0]).format("DD/MM/yyyy"),
                                },
                              });
                            },
                            minuteIncrement: 60,
                            disableMobile: true,
                            time_24hr: true,
                            minTime:
                              formValues.StartDate ===
                              moment().format("DD/MM/yyyy")
                                ? nowMomentDate
                                    .set("minutes", calculateMinTime())
                                    .format("HH:mm")
                                : null,
                          }}*/
                        />
                      </Layout.Item>
                    </Layout>
                  </FormField>
                </Layout.Item>
              </Layout>
            </Fieldset.Row>
          </Fieldset>
          <Fieldset>
            <Fieldset.Row>
              <Layout>
                <Layout.Item>
                  <FormField
                    labelId="endDateLabel"
                    labelText="Día y hora de devolución"
                    noFloatingLabel
                    type="other"
                  >
                    <Layout>
                      <Layout.Item default="1/2" s={"1/1"}>
                        <DatePicker
                          dateFormat="d/m/Y"
                          name={"EndDate"}
                          locale="es"
                          monthSelectorDropdown={true}
                          flatpickrOptions={{
                            onClose: (e) => {
                              handleOnChange({
                                target: {
                                  name: "EndDate",
                                  value: moment(e[0]).format("DD/MM/yyyy"),
                                },
                              });
                            },
                          }}
                          value={formValues.EndDate}
                          minDate={moment(
                            formValues.StartDate,
                            "DD/MM/yyyy"
                          ).toDate()}
                          maxDate={moment(formValues.StartDate, "DD/MM/yyyy")
                            .add("months", 3)
                            .toDate()}
                          onChange={(e) => {
                            handleOnChange({
                              target: { name: "EndDate", value: e },
                            });
                          }}
                        />
                      </Layout.Item>
                      <Layout.Item default="1/2" s={"1/1"}>
                        <TimePicker
                          name={"EndTime"}
                          value={formValues.EndTime}
                          minTime={nowMomentDate.format("HH:mm")}
                          locale="es"
                          onChange={(e) =>
                            handleOnChange({
                              target: { name: "EndTime", value: e },
                            })
                          } /*
                          flatpickrOptions={{
                            minuteIncrement: 60,
                            disableMobile: true,
                            time_24hr: true,
                            minTime:
                              formValues.StartDate ===
                              moment().format("DD/MM/yyyy")
                                ? nowMomentDate
                                    .set("minutes", calculateMinTime())
                                    .format("HH:mm")
                                : null,
                          }}*/
                        />
                      </Layout.Item>
                    </Layout>
                  </FormField>
                </Layout.Item>
              </Layout>
            </Fieldset.Row>
          </Fieldset>
          <Fieldset>
            <Fieldset.Row>
              <Layout flush>
                <Layout.Item default="1/1">
                  <FormField type="checkbox">
                    <Checkbox defaultChecked={true} disabled>
                      <span className="u-font-size-fs-2">
                        Devolver el vehículo en la misma oficina.
                      </span>
                      <InfoIcon icon="semantic-info">
                        Actualmente solo es posible realizar la recogida y la
                        devolución en el mismo lugar.
                      </InfoIcon>
                    </Checkbox>
                  </FormField>
                </Layout.Item>
                <Layout.Item className="u-mt-small" default="1/1">
                  <FormField type="other">
                    <CheckboxGroup>
                      <Checkbox
                        name={"Bigger25"}
                        defaultChecked={selectedBigger25}
                        onChange={(e) =>
                          dispatch(setSelectedBigger25(e.target.checked))
                        }
                      >
                        <span className="u-font-size-fs-2">
                          Conductor mayor de 25.
                        </span>
                        <InfoIcon icon="semantic-info">
                          Para poder alquilar un vehículo debes ser mayor de 21
                          años y tener un permiso de conducir con una antigüedad
                          mínima de 12 meses. Se aplicará un suplemento a
                          menores de 25 años.
                        </InfoIcon>
                      </Checkbox>
                    </CheckboxGroup>
                  </FormField>
                </Layout.Item>
              </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
              <Layout flush>
                <Layout.Item default="1/2" s="1/1">
                  <div
                    hidden={
                      selectedTariff == null ||
                      selectedTariff.TieneCodigoPromocional === 0
                    }
                  >
                    <FormField
                      labelId="PromotionalCode"
                      labelText="Código Promocional"
                      noFloatingLabel
                      type="other"
                    >
                      <Input
                        name={"PromotionalCode"}
                        value={formValues.PromotionalCode}
                        onChange={handleOnChange}
                      />
                    </FormField>
                  </div>
                </Layout.Item>
                <Layout.Item className="u-mt-small" default="1/1">
                  <FormField type="checkbox">
                    {showFormError ? (
                      <ErrorMessage>
                        {" "}
                        Por favor, completa correctamente el lugar y las fechas{" "}
                      </ErrorMessage>
                    ) : (
                      <div> </div>
                    )}
                  </FormField>
                </Layout.Item>
              </Layout>
            </Fieldset.Row>
          </Fieldset>

          <Fieldset>
            <Fieldset.Row>
              <Button full type="submit">
                Buscar
              </Button>
            </Fieldset.Row>
          </Fieldset>
        </React.Fragment>
      </Form>
      <ScheduleInfoModal> </ScheduleInfoModal>
    </Spinner>
  );
};
